import React, { useState, useEffect } from "react"
import Layout from "../components/layoutEn"
import SEO from "../components/layout/seo"
import RobotImg from "../img/robotImg.png"

import RobotImg1 from "../img/robotMain_01.png"
import RobotImg2 from "../img/robotMain_02.png"
import RobotImg3 from "../img/robotMain_03.png"
import RobotImg4 from "../img/robotMain_04.png"

import classNames from "classnames"

function useScroll(position) {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handler = () => {
      setScrolled(window.scrollY > position)
    }
    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [position])

  return scrolled
}

function Robot() {
  const scrolled = useScroll(3000)
  return (
    <Layout>
      <SEO title="M2MTech" />
      <div
        className={classNames(
          "elsaWrap backColor robotWrap",
          scrolled && "backColorAnimatedRobot"
        )}
      >
        <div className="sectionWrap elsaOverview robotOverview">
          <p className="elsaMainImg"></p>
          <div className="textWrap">
            <h2>
              Smart Mobility Infrastructure Service Platform
              <span>
                Serving Robot, Unmanned Delivery, Logistics Transfer in
                Factory/Warehouse
              </span>
            </h2>
            <div className="bodyText">
              From dish delivery in restaurants to unmanned delivery to
              logistical transport, robots can help people handle small tasks or
              move heavy goods instead of humans, improving people's quality of
              life and creating high-quality service experiences. <br />
              <br />
              Stable and secure interworking between robots and mobile
              facilities in buildings, such as elevators and escalators, is
              essential to providing a higher level of robot service. With the
              recent successful interlocking of M2MTech's integrated control
              system and logistics/serving/delivery robots, M2MTech is
              participating in many projects that are effective for human life
              in the robot industry.
            </div>
          </div>
        </div>
        <div className="sectionWrap elsaDetail robotDetail">
          <div className="textWrap">
            <h2>
              Smart delivery and logistics robots that address the development
              of new digital technologies, increased volume of goods, and labor
              shortages.
            </h2>
            <p className="bodyText">
              In the unmanned delivery service, interlocking with smart mobility
              devices, such as smart home control systems, elevator control, and
              courier service system of apartment complexes, is indispensable.
              Among robots, which are core devices in the digital transformation
              era, logistics robots are emerging as a promising field with high
              potential in resolving economic and social issues such as the
              aging population and productivity improvement.
            </p>
          </div>
          <div className="robotMainImg">
            <img src={RobotImg} alt="" />
          </div>
          <div className="textWrap">
            <h2>
              Two-way communication with elevators to ensure safe and fast
              movement of robots in buildings
            </h2>
            <p className="bodyText">
              M2MTech has developed a system that can move robots between
              floors, which can contribute to a well-established market for
              robot delivery services, which are expected to benefit consumers,
              such as preventing the spread of infectious diseases and crime
              through non-face-to-face delivery. Two-way communication with the
              elevator is essential to ensure the safety of the robot's vertical
              movement, but unilateral elevator control makes it difficult to
              check the safety status of the elevator. At this time, there are
              many global elevator manufacturers, and there are many models and
              models within one manufacturer, making it difficult to expand
              services. <br />
              <br />
              M2MTech has achieved international standard technology proposals
              and approvals to standardize new elevators and solve interlocking
              problems with existing elevators, safely link with various
              elevator models from various manufacturers with smart mobility
              infrastructure services, and ensure that robots and elevators are
              connected. By enabling smooth communication, we plan to contribute
              to a turning point in the expansion of the vertical mobile robot
              delivery service market. Recently, we are demonstrating a delivery
              service in the last mile section jointly with global autonomous
              robot companies at home and abroad.
            </p>
          </div>

          <div className="elsaBenefit robotBenefit colorYellow">
            <ul>
              <li>
                <p className="kind">
                  Residential environment integrated control
                </p>
              </li>
              <li>
                <p className="kind">High level of security</p>
              </li>
              <li>
                <p className="kind">
                  Interworking with various models from various manufacturers
                </p>
              </li>
              <li>
                <p className="kind">Patented technologies</p>
                <p className="kindDetail">
                  ‘승강기와 외부데이터 연동하는 방법 및 장치’, ‘네트워크 연동
                  승강기 관리 환경에서 보안 방법 및 장치’ 2건
                </p>
              </li>
            </ul>
          </div>
          <div className="robotProduct">
            <ul>
              <li>
                <div className="imgBox">
                  <img src={RobotImg1} alt="" />
                </div>
                <div className="textWrap">
                  <h2>
                    ELSA IoT Gateway<span className="tmText">TM</span>
                  </h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    System interoperability is ensured through interlocking
                    collection of elevator status and direct linkage with
                    elevators of various manufacturers.
                  </p>
                </div>
              </li>
              <li className="elsaServices1">
                <div className="imgBox">
                  <img src={RobotImg2} alt="" />
                </div>
                <div className="textWrap">
                  <h2>ELSA.Edge</h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    It is possible to connect the robot and the elevator
                    independently at the site level and to connect to the cloud
                    and Robot CMS.
                  </p>
                </div>
              </li>
              <li className="elsaServices2">
                <div className="imgBox">
                  <img src={RobotImg3} alt="" />
                </div>
                <div className="textWrap">
                  <h2>
                    ELSA IoT Gateway Lite<span className="tmText">TM</span>
                  </h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    Control/manage the access of delivery robots in conjunction
                    with automatic doors &amp; speed gates.
                  </p>
                </div>
              </li>
              <li className="elsaServices2">
                <div className="imgBox">
                  <img src={RobotImg4} alt="" />
                </div>
                <div className="textWrap">
                  <h2>ELSA Cloud</h2>
                  <p className="bodyText" style={{ paddingTop: 10 }}>
                    It supports inter-platform interworking services for
                    cloud-based robot-elevator delivery services.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="elsaBenefit robotBenefit"
            style={{ padding: "110px 0 40px" }}
          >
            <div className="robotBenefitTitle">
              <h2>
                Interworking field between Elsa integrated monitoring system
                and robot business
              </h2>
            </div>
            <ul>
              <li>
                <p className="kind">Self-driving logistics robot</p>
                <p className="kindDetail">
                  As the main purpose of logistics robots, AGVs (unmanned
                  transport routes), unmanned forklifts, mobile manipulators
                  (robot arms), and conveyor systems are used in conjunction
                  with WMS (warehouse management system). Recently, e-commerce
                  companies, which are experiencing a rapid increase in
                  processed goods, are using logistics robots for the purpose of
                  automating their logistics centers.
                </p>
              </li>
              <li>
                <p className="kind">Smart elevator + Robot</p>
                <p className="kindDetail">
                  The introduction of interlocking between robots and smart
                  elevators is increasing to transport documents and goods
                  indoors, such as commercial buildings, offices, and hospitals.
                  As an indoor autonomous lifting robot centered on transporting
                  small and medium-sized goods linked with an elevator, it is
                  currently being applied in various industries such as
                  distribution and logistics, manufacturing, hotel, medical
                  care, and construction.
                </p>
              </li>
              <li>
                <p className="kind">
                  Expanded to unmanned courier vertical delivery service
                </p>
                <p className="kindDetail">
                  High growth potential is expected against the backdrop of
                  securing the efficiency of the final delivery section of the
                  parcel delivery industry and excellent applicability to
                  various industries such as agriculture. The horizontal and
                  vertical delivery service through interlocking between robots
                  and elevators is expected to flourish as the Ministry of
                  Public Administration and Security notice was enacted on March
                  2nd for the 'Elevator Safety Management Act' from the existing
                  special cases. Convenience for consumers, such as prevention
                  of spread and crime, is expected.
                </p>
              </li>
            </ul>
          </div>

          <div className="elsaAchieve robotAchieve">
            <div className="textWrap">
              <h2>
                With the development of ICT convergence technology and smart
                home service, the non-face-to-face unmanned delivery service
                market in the “last mile section”, which accounts for more than
                50% of the total logistics cost, has unlimited growth potential.
              </h2>
              <p className="bodyText">
                In particular, the COVID-19 pandemic revitalizes the e-commerce
                market, with more than 60 parcels being used per person per year
                and 177 shipments per day per delivery driver. Accordingly,
                logistics (distribution) companies are promoting the development
                of smart mobile devices (delivery robots, etc.) to reduce
                delivery time and develop safe non-face-to-face delivery
                services. M2MTech Co., Ltd. activates a convergence ecosystem
                between different industries, such as smart home, logistics
                (distribution) service, and commercial robots, to improve the
                quality of life of the people who participate in final services
                or are consumers.
              </p>
            </div>
            <div className="elsaBenefit robotFuture">
              <ul>
                <li>
                  <p className="kindDetail">
                    Resolve logistics issues in the expensive last-mile section
                    with improved working conditions for platform workers
                    participating in the last mile section and safe
                    non-face-to-face delivery service.
                  </p>
                </li>
                <li>
                  <p className="kindDetail">
                    Various convergence new industry ecosystems can be expanded
                    by linking smart home services within the apartment complex
                    and external living services (purchase/shared delivery,
                    etc.).
                  </p>
                </li>
                <li>
                  <p className="kindDetail">
                    We secure service reliability and prevent safety accidents
                    in advance by establishing safety standards and
                    certification systems.
                  </p>
                </li>{" "}
                <li>
                  <p className="kindDetail">
                    Our work successfully innovates regulations between various
                    government departments (Ministry of Land, Infrastructure,
                    and Transport, Ministry of Science and Technology, etc.) and
                    promotes and utilizes government collaboration activities.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Robot
